.detail-item img {
  width: 18px;
  display: inline-block;
  margin-right: 10px;
}
.detail-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.schedule-grid {
  max-width: 800px;
  margin: auto !important;
  margin-top: 60px !important;
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
}
.schedule-grid.selected {
  max-width: 1000px;
}
.schedule-details {
  height: 560px;
  padding: 20px;
  border-right: 1px solid var(--lightGrey);
  overflow: scroll;
}
.detail-item p {
  word-break: break-word;
}
.schedule-details .artist-header,
.details-details .artist-header {
  justify-content: flex-start;
  border-bottom: 1px solid var(--lightGrey);
  padding-top: 20px;
}
.details-stack {
  margin: 20px 0px;
  max-height: 361px;
  overflow: scroll;
}
.calendar-box {
  margin-top: 40px;
}
.schedule-box {
  padding: 20px;
}
h3.timezone-header {
  font-size: 16px;
  margin-bottom: 5px;
}
.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;
}
button.month-nav-button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: none;
  background: var(--primaryLight);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
button.month-nav-button:disabled {
  background: none;
}
.calendar-header-text {
  display: flex;
  align-items: center;
}
.calendar-days h4 {
  text-align: center;
}
.calendar-days {
  border-bottom: 1px solid var(--lightGrey);
  padding-bottom: 10px;
}
.calendar-rows {
  margin: 0px;
}
.calendar-row .hidden p {
  display: none;
}
.calendar-row p {
  text-align: center;
  color: var(--outlineGrey);
}
div.day {
  height: 46px;
  align-items: center;
  justify-content: center;
  display: flex;
}
div.day.available {
  border-radius: 23px;
  background: #ffd4cc;
}
div.day.available p {
  font-weight: 600;
  color: var(--primary);
}
.time-box.hidden {
  display: none;
}
.time-box {
  margin-top: 40px;
  max-height: 502px;
  overflow: scroll;
}
.time-item {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  border: 1px solid var(--primary);
  border-radius: 5px;
}
div.schedule-box div.back {
  display: none;
}
.time-item h3 {
  color: var(--primary);
}
.schedule-grid-item {
  height: 600px;
}
.schedule-box {
  height: 560px;
}
.review-container.confirm {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    90deg,
    var(--lightGrey),
    var(--lightGrey) 50%,
    #fff 50%,
    #fff
  );
  margin-bottom: 0px;
  margin-top: 0px;
}
.schedule-detail-item {
  display: flex;
  width: 100%;
  gap: 10px;
}
div.schedule-detail-item div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.back img {
  width: 24px;
  height: 24px;
}
.back {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background: var(--primary);
  margin-bottom: 40px;
}
div.appointment-details .back {
  position: relative;
}
.details-header {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--lightGrey);
  position: sticky;
}
div.back svg {
  color: #fff;
}
.review-stack {
  padding-top: 20px;
  padding-bottom: 20px;
}
.payment-row {
  display: flex;
  justify-content: space-between;
}
div.back a {
  display: flex;
}
div.review-left {
  max-height: 95vh;
  overflow: scroll;
  margin-bottom: 5vh;
}
.booking-login form {
  margin-top: 40px;
}
form.code-form h2 {
  margin-bottom: 0px;
}
form.code-form p {
  margin-bottom: 60px;
  text-align: center;
}
form#payment-form {
  margin-top: 20px;
  padding-bottom: 0;
}
.waiver-checkbox {
  width: 100%;
}
div.waiver {
  padding: 20px;
  max-height: 30vh;
  overflow: scroll;
  text-align: justify;
  color: var(--darkGrey);
  border: 1px solid var(--outlineGrey);
  background: var(--lightGrey);
  box-shadow: none;
}
.payment-section {
  margin-top: 20px;
}
div#payment-element iframe {
  height: 420px !important;
}
.payment-details {
  padding: 20px 16px;
  border-top: 1px solid var(--lightGrey);
  border-bottom: 1px solid var(--lightGrey);
}
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
div.schedule-grid-item.action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 16px;
}
.calendar-days,
.calendar-rows {
  padding: 0px 5px;
  max-width: 322px;
  margin: auto;
}
.time-stack {
  padding: 0px 16px;
}
div.schedule-grid-item .artist-header,
div.appointment-details .artist-header {
  justify-content: flex-start;
  border-bottom: 1px solid var(--lightGrey);
  padding: 40px 0px 20px 0px;
  margin-bottom: 20px;
}
div.schedule-grid-item div.artist-header-details h1 {
  color: var(--darkGrey);
}
.appointment-details .schedule-details {
  height: auto;
  overflow: visible;
  border-right: none;
}
.payment-total p {
  font-weight: 600;
}
button.change-payment {
  font-weight: 400;
  font-size: 14px;
}
button.change-payment:hover {
  background: none;
}
div.add-new-payment {
  width: calc(100% - 32px);
  padding: 20px 16px;
}
div.add-new-payment div#payment-element {
  padding: 0;
}
div#payment-element {
  padding: 0px 16px;
}
button.chat-text-btn {
  float: left;
  margin-top: 10px;
  padding: 0px;
  font-weight: 400;
  text-decoration: underline;
  color: var(--darkGrey);
}
button.chat-text-btn:hover {
  background-color: transparent;
}
@media (max-width: 1120px) {
  div.MuiContainer-maxWidthLg {
    padding: 20px;
  }
}
@media (max-width: 900px) {
  div.schedule-grid {
    border: none;
    border-radius: 0px;
    margin-top: 0px !important;
  }
  .timezone-box-desktop p {
    text-align: center;
  }
  .schedule-details h1 {
    text-align: center;
  }
  div.schedule-grid-item .artist-header,
  div.appointment-details .artist-header {
    justify-content: center;
    border-bottom: none;
    padding: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  div.schedule-grid-item .artist-header h1,
  div.appointment-details .artist-header h1 {
    text-align: left;
  }
  .schedule-details .artist-header h1,
  .appointment-details .artist-header h1 {
    text-align: left;
  }
  .schedule-details .artist-header,
  .appointment-details .artist-header {
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
  }
  .schedule-details {
    padding: 20px 0px;
    border-right: none;
    height: auto;
    overflow: visible;
  }
  .schedule-grid-item {
    height: auto;
  }
  .details-stack {
    display: block;
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .schedule-box h3 {
    text-align: center;
  }
  .calendar-box.hidden {
    display: none;
  }
  .review-container.confirm {
    background: #fff;
    box-shadow: none;
    height: auto;
  }
  .appointment-details h1 {
    text-align: center;
  }
  .review-stack h3,
  .review-stack p {
    text-align: center;
  }
  a.review-link {
    position: relative;
    top: 5px;
  }
  div.review-left {
    max-height: none;
    margin-bottom: 0px;
  }
  .hidden-sm {
    display: none;
  }
  .schedule-box {
    padding-left: 0px;
    padding-right: 0px;
    border-top: 1px solid var(--lightGrey);
  }
  .appointment-details {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .appointment-waiver {
    padding-top: 0px;
  }
  .action-buttons {
    align-items: flex-start;
  }
  div.schedule-box div.back {
    display: flex;
  }
}
div.day.available.hidden {
  background: none;
}
.checkout-flex {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  padding-bottom: 120px;
}
.checkout-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid var(--lightGrey);
}
.checkout-table {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 16px;
  border-bottom: 1px solid var(--lightGrey);
}
.checkout-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-row p.grow,
.checkout-row h4.grow {
  flex-grow: 1;
}
.tip-summary {
  display: flex;
  justify-content: space-between;
}
.checkout-tips {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 16px;
  border-bottom: 1px solid var(--lightGrey);
}
.tip-suggestions {
  display: flex;
  background: var(--lightGrey);
  border-radius: 5px;
  padding: 5px;
}
.tip-suggestions button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 5px;
  background-color: var(--lightGrey);
  padding: 10px 10px;
}
.tip-suggestions button.active {
  background-color: #ffffff;
  color: var(--primary);
}
.tip-suggestions button.active h4 {
  color: var(--primary);
}
button.grey {
  background: var(--lightGrey);
  border-radius: 1px;
  padding: 15px 40px;
}
button.grey.active {
  background-color: #1078da;
}
button.grey.active p {
  color: #fff;
}
.payment-method {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 16px;
}
.card-details {
  display: flex;
  align-items: center;
  gap: 5px;
}
.payment-method button {
  flex-grow: 1;
  justify-content: flex-end;
}
.payment-method button p {
  color: #1078da;
}
button.next.done.checkout {
  width: auto;
  box-shadow: none;
  margin-top: 0px;
  margin-bottom: 5px;
}
div.checkout-header .MuiAvatar-root {
  width: 75px;
  height: 75px;
  margin-bottom: 5px;
}
.footer-sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 0px;
  background-color: #fff;
}
p.center {
  text-align: center;
}
.checkout-flex p,
.checkout-flex h4 {
  font-size: 14px;
}
div.checkout-flex div.tip-input input {
  font-size: 14px;
  line-height: 14px;
  padding: 15px;
}
.appointment-link-box {
  width: calc(100% - 22px);
  display: flex;
  border: 1px solid var(--lightGrey);
  border-radius: 10px;
  padding: 20px 10px;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.calendar-days {
  margin-bottom: 10px;
  padding-bottom: 5px;
}
